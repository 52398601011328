import { manageRouteDefaultConfig } from '../../router';

export default {
	'/bonuses/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'bonuses',
		module: () => import('./'),
	},
	'/bonuses/:companyuser/:bonuslistId': {
		...manageRouteDefaultConfig,
		as: 'bonuses',
		module: () => import('./'),
	},
};
