import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/watchlists/:companyuser/:listId': {
		...manageRouteDefaultConfig,
		as: 'watchlists',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/watchlists/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'watchlists',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
