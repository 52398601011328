import { manageRouteDefaultConfig, type ManageRoute } from '../../../router';
import type { ArbitraryArrowFunction } from '@router/types';

export default <Record<string, ManageRoute>>{
	'/redirecting/hubspot?:code': {
		...manageRouteDefaultConfig,
		as: 'redirectHubspot',
		module: () => import('./'),
		uses: (match, ymdRoute, router) =>
			(ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
				match.params?.code,
				match.params?.state,
				router.navigate,
			),
	},
};
