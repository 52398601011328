import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/custom-db-queries': {
		...manageRouteDefaultConfig,
		as: 'custom-db-queries',
		isCompanyUserRequired: false,
		module: () => import('./'),
	},
};
