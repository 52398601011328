import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/lists/:companyuser/:moduleId/:jobid/:subtemplate/:callfilter': {
		...manageRouteDefaultConfig,
		as: 'lists',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/lists/:companyuser/:moduleId/:jobid/:subtemplate': {
		...manageRouteDefaultConfig,
		as: 'lists',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/lists/:companyuser/:moduleId/:jobid': {
		...manageRouteDefaultConfig,
		as: 'lists',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/lists/:companyuser/:moduleId': {
		...manageRouteDefaultConfig,
		as: 'lists',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
