import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/talent/:talentId': {
		...manageRouteDefaultConfig,
		as: 'talentdetails',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
