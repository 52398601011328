import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/quality/qualitycheckdetails/:companyuser/:talentid/:formid/:reviewid': {
		...manageRouteDefaultConfig,
		as: 'quality/quality-check-details',
		module: () => import('./quality-check-details'),
	},
	'/quality/:companyuser/:talentid/:formid': {
		...manageRouteDefaultConfig,
		as: 'quality',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/quality/:companyuser/:formid': {
		...manageRouteDefaultConfig,
		as: 'quality',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/quality/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'quality',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
