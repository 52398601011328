import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/workforce/:companyuser/:moduleId/:teamIds/:contractState': {
		...manageRouteDefaultConfig,
		as: 'workforce',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/workforce/:companyuser/:moduleId/:contractState': {
		...manageRouteDefaultConfig,
		as: 'workforce',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/workforce/:companyuser/:moduleId': {
		...manageRouteDefaultConfig,
		as: 'workforce',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/workforce/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'workforce',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
