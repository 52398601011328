import { User } from './User';
import { createAlertMessageEvent } from '@components/app-base/custom-events';
import { getAppElement } from '@components/app-base/helper';
import type { YmdRoute } from '@router/types';
import YmmdRouter from '@router';
import { Log } from '@modules/Log';
import { matomoTracker } from '@modules/matomoTracker';
import { socket } from '@modules/Socket';

export const Router = new YmmdRouter(import.meta.env.BASE_URL);

Router.notFound(() => {
	const redirectTo = User.hasSession ? '/notfound' : '/login';
	Router.navigate(redirectTo, {
		updateBrowserURL: false,
	});
	if (User.hasSession) {
		const { url } = Router.getCurrentLocation();
		Log.error(new Error(`Not found: ${url}`));
	}
});

export type ManageRoute = YmdRoute & {
	hasRocketchat?: boolean;
	isCompanyUserRequired?: boolean;
	isRecruiting?: boolean;
};

const sessionOk = (ymdRoute: ManageRoute) => {
	if (ymdRoute.isAuthRequired && !User.hasSession && ymdRoute.as !== 'login') {
		Router.navigate('/login', {
			updateBrowserURL: false,
		});
		return false;
	}
	return true;
};

const isAccessAllowed = (ydRoute: ManageRoute): boolean => {
	if (
		User.hasSession &&
		!User.canAccess(ydRoute.as, ydRoute.isCompanyUserRequired)
	) {
		getAppElement()?.dispatchEvent(
			createAlertMessageEvent(window.T.alert.error.notallowed, 'warning'),
		);
		return false;
	}
	return true;
};

const shouldRedirectToProjectOverview = async (
	ydRoute: ManageRoute,
): Promise<boolean> => {
	const companies = (await User.clients) || [];
	if (ydRoute.as === 'home' && companies.length === 1) {
		Router.navigate(`/projects/${User.company?.id}`);
		return false;
	}
	return true;
};

export const manageRouteDefaultConfig: Omit<ManageRoute, 'as'> = {
	hasPageTracking: true,
	isCompanyUserRequired: true,
	hasRocketchat: false,
	hooks: {
		before: async (done, match, ymdRoute: ManageRoute) => {
			if (ymdRoute.isCompanyUserRequired) {
				await User.setCompany(match.data?.companyuser);
			}
			done(
				sessionOk(ymdRoute) &&
					isAccessAllowed(ymdRoute) &&
					(await shouldRedirectToProjectOverview(ymdRoute)),
			);
		},
		after: (match, ymdRoute) => {
			if (ymdRoute.hasPageTracking) matomoTracker.pageTrack();
			socket.initAndAuthorize(
				User.company?.websocket,
				import.meta.env.VITE_YOUMMDAY_SOCKET,
			);
		},
	},
};
