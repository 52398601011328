import type { ArbitraryArrowFunction } from '@router/types';
import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

// Paypal charge success url
export default <Record<string, ManageRoute>>{
	'/finance/:result': {
		...manageRouteDefaultConfig,
		as: 'paypalCallback',
		hasRocketchat: true,
		module: () => import('./'),
		uses: (match, ymdRoute, router) =>
			(ymdRoute.routeDefaultExport as ArbitraryArrowFunction)(
				match.params?.token,
				match.data?.result,
				router.navigate,
			),
	},
};
