import AppBase from '@components/app-base';
import { header } from './header.js';
import { aside } from './aside.js';
import { customElement, query, state } from 'lit/decorators.js';
import { render, html, nothing } from 'lit';
import { when } from 'lit/directives/when.js';
import { User } from './User';
import { classMap } from 'lit/directives/class-map.js';
import { audioControls } from '../AudioPlayer.js';
import { NavigationScope } from './components/navigation-switch/types';
import './components/setup-progress';
import '@components/chat-launcher';
import { createNavigateEvent } from '@components/app-base/custom-events';
import { type PushMsg, socket } from '@modules/Socket';
import { i18n } from '@i18n';
import Template from '../Template.js';
import '@utils/formSerializeObject.js';
import { br, notification } from '@utils';
import { Storage } from '@modules/Storage';
import { Log } from '@modules/Log.js';
import { API } from './api';
import { Router, type ManageRoute } from './router';
import type { PageBase } from '@components/page-base';

@customElement('app-manage')
export default class AppManage extends AppBase {
	view?: PageBase | Template;
	// deprecated: legacy container is for Template Class Instance
	@query('#legacy-page') legacyContainer!: HTMLElement;

	@state() route?: ManageRoute;
	@state() scope: NavigationScope = 'default';

	localStorage = new Storage(this.nameSpace, 'local');

	connectedCallback() {
		this.addEventListeners();

		super.connectedCallback();
		Router.subscribe(async (route, params, queryParams) => {
			if (!route.routeDefaultExport) return;
			if (this.view instanceof Template) this.view.destroy();
			if (route.routeDefaultExport.isLegacy) {
				this.view = new route.routeDefaultExport(
					// eslint-disable-next-line no-undefined
					undefined,
					params || {},
					queryParams || {},
					route,
				) as Template;
				if (!this.view) return;

				render(nothing, this);
				render(this.view.container, this.legacyContainer);
				await this.view.init();
			} else {
				this.view = new route.routeDefaultExport();
				if (!this.view) return;
				this.view.params = params || {};
				this.view.query = queryParams || {};
				this.view.route = route;
				render(nothing, this.legacyContainer);
				render(this.view, this);
			}
			this.route = route;
		});
	}

	addEventListeners() {
		socket.Log = Log;
		socket.subscribe(this, 'push', ({ detail: { msgs = [] } }) => {
			if (
				!msgs.some(
					({ answer, id }) =>
						!answer && // not answered yet
						!(this.localStorage.get('seenPushes') || []).includes(id), // not yet seen
				)
			) {
				return;
			}
			const singlePush = (push: PushMsg) => {
				const container = document.createElement('div');
				render(
					html`
						<div style="display:flex; align-items:center;">
							<div>
								<user-thumb
									.user=${push.user || { ymdItself: true }}
									size="40"
								></user-thumb>
							</div>
							<div>
								<h5 class="m-0">${push.title}</h5>
							</div>
						</div>
						<p>${br(push.text)}</p>
					`,
					container,
				);
				return container;
			};
			notification(window.T.message.event.pushmsg, '', i18n.language);
			msgs.forEach((push) => {
				const container = singlePush(push);
				this.onShowToast(
					new CustomEvent('showToast', {
						detail: {
							element: container,
							variant: 'neutral',
							onDismissed: () => {
								this.localStorage.push('seenPushes', push.id);
							},
						},
					}),
				);
			});
		});
		this.addEventListener(
			'switchNavigationScope',
			({ detail: { scope } }: CustomEvent) => {
				this.scope = scope;
			},
		);
		// [data-for] and [name="tabs-*"] evt listening should be replaced with something new and <yd-tabs>
		this.shadowRoot?.addEventListener('change', (e) => {
			const target = e.target as HTMLInputElement;

			if (typeof target.name === 'string' && target.name?.startsWith('tab')) {
				this.shadowRoot?.querySelectorAll('[for^="tab"]').forEach((forTab) => {
					const activateTab = this.shadowRoot?.querySelector(
						`#${forTab.getAttribute('for')}`,
					) as HTMLInputElement;
					if (activateTab && 'checked' in activateTab) {
						forTab.classList.toggle('active', activateTab.checked);
					}
				});
			}

			if (target.hasAttribute('data-for')) {
				const dataFor = target.getAttribute('data-for') as string;
				const checkboxes = this.shadowRoot?.querySelectorAll(
					dataFor,
				) as NodeListOf<HTMLInputElement>;
				checkboxes.forEach((checkbox) => {
					checkbox.checked = target.checked;
					checkbox.dispatchEvent(new Event('change', { bubbles: true }));
				});
			}
		});
	}

	onLogout({ detail: onPurpose }: CustomEvent<boolean>) {
		delete User.user;
		Router.navigate('/login', {
			updateBrowserURL: false,
		});
		socket.kill();
		sessionStorage.clear();
		if (!onPurpose) {
			this.showSnackBar({
				str: window.T.alert.error.auth,
				variant: 'danger',
			});
		} else {
			API.POST('/logout');
		}
	}

	onNavigate(e: ReturnType<typeof createNavigateEvent>) {
		Router.navigate(e.detail);
	}

	// just a bridge for legacy Model-Class
	userUpdated(user: APIuser) {
		User.updateUser(user);
	}

	render() {
		return html`
			<article class=${classMap({ 'has-header': !!this.route?.hasHeader })}>
				${when(
					User.hasSession,
					() => html`
						${when(
							this.route?.hasAsideNavigation,
							() => html`
								<aside>${aside(this.scope)}</aside>
							`,
						)}
					`,
				)}

				<main>
					${when(
						User.hasSession && User.company?.wantsSetupProgress === 1,
						() => html`
							<setup-progress
								.currentRoute=${Router.getCurrentLocation()?.url}
							></setup-progress>
						`,
					)}
					${audioControls}
					<section
						class=${classMap({
							'no-padding':
								!this.route?.hasHeader && !this.route?.hasAsideNavigation,
						})}
					>
						${when(
							this.isLoading,
							() => html`
								<div class="loading-bar"></div>
							`,
						)}
						<slot></slot>
						<div id="legacy-page"></div>
					</section>
				</main>
				${when(
					User.hasSession && this.route?.hasRocketchat,
					() => html`
						<chat-launcher
							.collisionPrevention=${User.isSelfservice}
						></chat-launcher>
					`,
				)}
			</article>

			${when(
				this.route?.hasHeader && User.hasSession,
				() => html`
					<header>${header(this.scope)}</header>
				`,
			)}

			<div class="modal"></div>

			${when(this.dialog, () => this.renderDialog())}
			<!---->
			${this.renderSnackBar()}
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'app-manage': AppManage;
	}
	interface HTMLElementEventMap {
		switchNavigationScope: CustomEvent<{ scope: NavigationScope }>;
	}
}
