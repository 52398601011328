import { manageRouteDefaultConfig, type ManageRoute } from '../router';

export default <Record<string, ManageRoute>>{
	'/qualityadd/:companyuser/:formid': {
		...manageRouteDefaultConfig,
		as: 'qualityadd',
		hasRocketchat: true,
		module: () => import('./qualityadd.js'),
	},
	'/qualityadd/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'qualityadd',
		hasRocketchat: true,
		module: () => import('./qualityadd.js'),
	},
	'/qualitycheck/:companyuser/:talentid/:formid': {
		...manageRouteDefaultConfig,
		as: 'qualitycheck',
		hasRocketchat: true,
		module: () => import('./qualitycheck.js'),
	},
	'/shiftplanning/:companyuser/:calendarId/:moduleId': {
		...manageRouteDefaultConfig,
		as: 'shiftplanning',
		module: () => import('./shiftplanning.js'),
	},
	'/shiftplanning/:companyuser/:calendarId': {
		...manageRouteDefaultConfig,
		as: 'shiftplanning',
		module: () => import('./shiftplanning.js'),
	},
	'/shiftplanning/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'shiftplanning',
		module: () => import('./shiftplanning.js'),
	},
};
