import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/findtalents/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'recruitment/findtalents',
		hasRocketchat: true,
		module: () => import('./findtalents'),
	},
	'/recruitment/findtalents/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'recruitment/findtalents',
		hasRocketchat: true,
		isRecruiting: true,
		module: () => import('./findtalents'),
	},
	'/recruitment/worktypes': {
		...manageRouteDefaultConfig,
		as: 'recruitment/worktypes',
		module: () => import('./worktypes'),
	},
	'/recruitment/worktypes/create': {
		...manageRouteDefaultConfig,
		as: 'recruitment/worktype',
		module: () => import('./worktype'),
	},
	'/recruitment/worktypes/edit/:id': {
		...manageRouteDefaultConfig,
		as: 'recruitment/worktype',
		module: () => import('./worktype'),
	},
	'/recruitment/job-offers': {
		...manageRouteDefaultConfig,
		as: 'recruitment/job-offers',
		module: () => import('./job-offers'),
	},
	'/recruitment/job-offers/create': {
		...manageRouteDefaultConfig,
		as: 'recruitment/job-offer',
		module: () => import('./job-offer'),
	},
	'/recruitment/job-offers/edit/:id': {
		...manageRouteDefaultConfig,
		as: 'recruitment/job-offer',
		module: () => import('./job-offer'),
	},
	'/recruitment/management': {
		...manageRouteDefaultConfig,
		as: 'recruitment/management',
		module: () => import('./management'),
	},
};
