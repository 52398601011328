import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/customreport/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'customreport',
		module: () => import('./'),
	},
	'/customreport/:companyuser/:queryid': {
		...manageRouteDefaultConfig,
		as: 'customreport',
		module: () => import('./'),
	},
};
