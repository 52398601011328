import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/teams/:companyuser/:teamId': {
		...manageRouteDefaultConfig,
		as: 'teams',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/teams/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'teams',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
