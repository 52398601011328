import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/mailbox/:companyuser/:conversationId': {
		...manageRouteDefaultConfig,
		as: 'mailbox',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/mailbox/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'mailbox',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
