import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/project/:companyuser/:mnemonic/:settingsgroup': {
		...manageRouteDefaultConfig,
		as: 'project',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/project/:companyuser/:mnemonic': {
		...manageRouteDefaultConfig,
		as: 'project',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/project/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'project',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
