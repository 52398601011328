import createClient from 'openapi-fetch';
import {
	deepNestedObjectToQueryString,
	objectToFormData,
} from '@API/serializer';
import type { paths } from './@types/API';

const baseURLv1 = `${import.meta.env.VITE_API_HOST}manage/`;
export const APIv2 = createClient({
	baseUrl: `${import.meta.env.VITE_API_HOST}v2/`,
	credentials: 'include',
});

export const API = createClient<paths>({
	baseUrl: baseURLv1,
	credentials: 'include',
	bodySerializer: deepNestedObjectToQueryString,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
	// signal: AbortSignal.timeout(1),
});

export const UploadAPI = createClient<paths>({
	baseUrl: baseURLv1,
	bodySerializer: objectToFormData,
	credentials: 'include',
});
