import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/training/:trainingId': {
		...manageRouteDefaultConfig,
		as: 'training',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/training': {
		...manageRouteDefaultConfig,
		as: 'training',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
