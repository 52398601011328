import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/trainings/:trainingId': {
		...manageRouteDefaultConfig,
		as: 'trainings',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/trainings': {
		...manageRouteDefaultConfig,
		as: 'trainings',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
