import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/shifts/:companyuser/:calendarId/:moduleId': {
		...manageRouteDefaultConfig,
		as: 'shifts',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/shifts/:companyuser/:calendarId': {
		...manageRouteDefaultConfig,
		as: 'shifts',
		hasRocketchat: true,
		module: () => import('./'),
	},
	'/shifts/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'shifts',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
