/* eslint-disable max-lines */
import { User } from '@app/User.ts';
import { html } from 'lit';
import { when } from 'lit/directives/when.js';
import { globals } from '@globals';
import { openHubspotForm } from '@utils/index.ts';
import { matomoTracker } from '@modules/matomoTracker.ts';
import { Router } from '@app/router.ts';
import './components/navigation-switch/navigation-switch.ts';

import '@shoelace-style/shoelace/dist/components/copy-button/copy-button.js';

const matomoEventNamesPrefix = {
	main: 'mg.nav.main',
	sub: 'mg.nav.sub',
	recruiting: 'mg.nav.recruiting',
	more: 'mg.nav.more',
};

const matomoEventNames = {
	copy: `${matomoEventNamesPrefix.main}.companyid.copy.click`,
	// Main navigation
	projects: `${matomoEventNamesPrefix.main}.projects.click`,
	workforce: `${matomoEventNamesPrefix.main}.workforce`,
	// Sub Workforce
	talentsAndRecruiting: `${matomoEventNamesPrefix.sub}.worforce.talentsAndRecruiting.click`,
	scouting: `${matomoEventNamesPrefix.sub}.worforce.scouting.click`,
	teams: `${matomoEventNamesPrefix.sub}.worforce.teams.click`,
	watchlists: `${matomoEventNamesPrefix.sub}.worforce.watchlists.click`,
	trainings: `${matomoEventNamesPrefix.sub}.worforce.trainings.click`,
	linkUpIds: `${matomoEventNamesPrefix.sub}.worforce.linkUpIds.click`,
	// EO Sub Workforce
	monitoring: `${matomoEventNamesPrefix.main}.monitoring.click`,
	dialerKpi: `${matomoEventNamesPrefix.main}.dialerKpi.click`,
	shifts: `${matomoEventNamesPrefix.main}.shiftPlanning.click`,
	shiftPlanning: `${matomoEventNamesPrefix.main}.shiftPlanningNew.click`,
	qualityAssurance: `${matomoEventNamesPrefix.main}.qualityAssurance.click`,
	callRecordings: `${matomoEventNamesPrefix.main}.callRecordings.click`,
	statistics: `${matomoEventNamesPrefix.main}.statistics.click`,
	// Sub Statistics
	callVolume: `${matomoEventNamesPrefix.sub}.statistics.callVolume.click`,
	talents: `${matomoEventNamesPrefix.sub}.statistics.talents.click`,
	reports: `${matomoEventNamesPrefix.sub}.statistics.reports.click`,
	// EO Sub Statistics
	finance: `${matomoEventNamesPrefix.main}.finance.click`,
	company: `${matomoEventNamesPrefix.main}.company.click`,
	// EO Main navigation
	// Recruiting navigation
	worktypes: `${matomoEventNamesPrefix.recruiting}.worktypes.click`,
	jobOffers: `${matomoEventNamesPrefix.recruiting}.jobOffers.click`,
	// EO Recruiting navigation
	// More navigation
	vpnLogins: `${matomoEventNamesPrefix.more}.vpnLogins.click`,
	talentRevenues: `${matomoEventNamesPrefix.more}.talentRevenues.click`,
	notifications: `${matomoEventNamesPrefix.more}.notifications.click`,
	monitoringPermissions: `${matomoEventNamesPrefix.more}.monitoringPermissions.click`,
	fees: `${matomoEventNamesPrefix.more}.fees.click`,
	// EO More navigation
};

const setActiveIf = (path) => {
	const { url } = Router.getCurrentLocation();
	if (
		(path && (url === path || url.startsWith(`${path}/`))) ||
		(!path && !url)
	) {
		return 'active';
	}
	return '';
};

const setActiveIfOneOfMany = (arr) => (arr.some(setActiveIf) ? 'active' : '');

const isExpanded = (paths = []) => paths.some((p) => setActiveIf(p));

const renderDefaultNav = () => {
	if (!User.company) return '';
	const { modules = {} } = User.company || {};
	const dialerModules = Object.values(modules)
		.filter((m) => m.type === 'dialer')
		.sort((m, b) =>
			(m.title || window.T.term.untitled).localeCompare(
				b.title || window.T.term.untitled,
			),
		);
	const userHasDialerProjects = dialerModules.length > 0;

	return html`
		<app-link
			href="projects/${User.company.id}"
			class=${setActiveIf('projects')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.projects })}
		>
			<span>
				<iconify-icon
					icon="mdi-puzzle"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.myservices}
		</app-link>
		<label for="toggletalents">
			<span>
				<iconify-icon
					icon="mdi-account-group"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.workforce}
		</label>
		<input
			type="checkbox"
			id="toggletalents"
			.checked=${isExpanded([
				'findtalents',
				'workforce',
				'teams',
				'chatgroups',
				'watchlists',
				'trainings',
				'mapping',
			])}
			@change=${(e) => {
				matomoTracker.track({
					name: `${matomoEventNames.workforce}.${e.target.checked ? 'expanded' : 'collapsed'}`,
				});
			}}
		/>
		<div>
			${when(
				User.canAccess('workforce'),
				() => html`
					<app-link
						href="workforce/${User.company.id}"
						class=${setActiveIf('workforce')}
						@click=${() =>
							matomoTracker.track({
								name: matomoEventNames.talentsAndRecruiting,
							})}
					>
						<span>
							<iconify-icon
								icon="mdi-handshake"
								class="iconify"
							></iconify-icon>
						</span>
						${window.T.nav.talentpool}
					</app-link>
				`,
			)}
			<app-link
				href="findtalents/${User.company.id}"
				class=${setActiveIf('findtalents')}
				@click=${() => matomoTracker.track({ name: matomoEventNames.scouting })}
			>
				<span>
					<iconify-icon
						icon="mdi-account-search"
						class="iconify"
					></iconify-icon>
				</span>
				${window.T.nav.findtalents}
			</app-link>
			${when(
				User.canAccess('teams'),
				() => html`
					<app-link
						href="teams/${User.company.id}"
						class=${setActiveIf('teams')}
						@click=${() =>
							matomoTracker.track({ name: matomoEventNames.teams })}
					>
						<span>
							<iconify-icon
								icon="mdi:google-circles-communities"
								class="iconify"
							></iconify-icon>
						</span>
						${window.T.nav.teams}
					</app-link>
				`,
			)}
			${when(
				User.canAccess('watchlists'),
				() => html`
					<app-link
						href="watchlists/${User.company.id}"
						class=${setActiveIf('watchlists')}
						@click=${() =>
							matomoTracker.track({ name: matomoEventNames.watchlists })}
					>
						<span>
							<iconify-icon
								icon="mdi-account-eye"
								class="iconify"
							></iconify-icon>
						</span>
						${window.T.nav.watchlists}
					</app-link>
				`,
			)}
			${when(
				User.canAccess('trainings'),
				() => html`
					<app-link
						href="trainings/${User.company.id}"
						class=${setActiveIf('trainings')}
						@click=${() =>
							matomoTracker.track({ name: matomoEventNames.trainings })}
					>
						<span>
							<iconify-icon
								icon="mdi-school"
								class="iconify"
							></iconify-icon>
						</span>
						${window.T.nav.trainings}
					</app-link>
				`,
			)}
			${when(
				User.canAccess('mapping'),
				() => html`
					<app-link
						href="mapping/${User.company.id}"
						class=${setActiveIf('mapping')}
						@click=${() =>
							matomoTracker.track({ name: matomoEventNames.linkUpIds })}
					>
						<span>
							<iconify-icon
								icon="mdi:vector-link"
								class="iconify"
							></iconify-icon>
						</span>
						${window.T.nav.mapping}
					</app-link>
				`,
			)}
		</div>

		<app-link
			href="monitor/${User.company.id}"
			class=${setActiveIf('monitor')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.monitoring })}
		>
			<span>
				<iconify-icon
					icon="mdi:monitor-dashboard"
					class="iconify"
					flip="horizontal"
				></iconify-icon>
			</span>
			${window.T.nav.monitoring}
		</app-link>

		${when(
			userHasDialerProjects,
			() => html`
				<app-link
					href="dialerkpi/${User.company.id}"
					class=${setActiveIf('dialerkpi')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.dialerKpi })}
				>
					<span>
						<iconify-icon
							icon="mdi-phone-dial"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.dialerkpi}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('shifts'),
			() => html`
				<app-link
					href="shifts/${User.company.id}"
					class=${setActiveIf('shifts')}
					@click=${() => matomoTracker.track({ name: matomoEventNames.shifts })}
				>
					<span>
						<iconify-icon
							icon="mdi-calendar-month"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.schedule}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('shiftplanning'),
			() => html`
				<app-link
					href="shiftplanning/${User.company.id}"
					class=${setActiveIf('shiftplanning')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.shiftPlanning })}
				>
					<span>
						<iconify-icon
							icon="mdi-calendar-month"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.schedule} (new)
				</app-link>
			`,
		)}
		${when(
			User.canAccess('quality'),
			() => html`
				<app-link
					href="quality/${User.company.id}"
					class=${setActiveIfOneOfMany([
						'quality',
						'qualityadd',
						'qualitycheck',
						'qualityview',
					])}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.qualityAssurance })}
				>
					<span>
						<iconify-icon
							icon="mdi-clipboard-check-multiple"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.quality}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('voicefiles'),
			() => html`
				<app-link
					href="voicefiles/${User.company.id}"
					class=${setActiveIf('voicefiles')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.callRecordings })}
				>
					<span>
						<iconify-icon
							icon="mdi-waveform"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.recordings}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('stats') || User.canAccess('report'),

			() => html`
				<label for="togglestats">
					<span>
						<iconify-icon
							icon="mdi-chart-bar"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.stats}
				</label>
				<input
					type="checkbox"
					id="togglestats"
					?checked=${isExpanded(['stats', 'stats-talents', 'report'])}
					.checked=${isExpanded(['stats', 'stats-talents', 'report'])}
					@change=${(e) => {
						matomoTracker.track({
							name: `${matomoEventNames.statistics}.${e.target.checked ? 'expanded' : 'collapsed'}`,
						});
					}}
				/>
				<div>
					${when(
						User.canAccess('stats'),
						() => html`
							<app-link
								href="stats/${User.company.id}"
								class=${setActiveIf('stats')}
								@click=${() =>
									matomoTracker.track({ name: matomoEventNames.callVolume })}
							>
								<span>
									<iconify-icon
										icon="mdi-chart-pie"
										class="iconify"
									></iconify-icon>
								</span>
								${window.T.nav.volume}
							</app-link>
							<app-link
								href="stats-talents/${User.company.id}"
								class=${setActiveIf('stats-talents')}
								@click=${() =>
									matomoTracker.track({ name: matomoEventNames.talents })}
							>
								<span>
									<iconify-icon
										icon="mdi-account-group"
										class="iconify"
									></iconify-icon>
								</span>
								${window.T.nav.talent_stats}
							</app-link>
						`,
					)}
					${when(
						User.canAccess('report'),
						() => html`
							<app-link
								href="report/${User.company.id}"
								class=${setActiveIf('report')}
								@click=${() =>
									matomoTracker.track({ name: matomoEventNames.reports })}
							>
								<span>
									<iconify-icon
										icon="mdi-file-table"
										class="iconify"
									></iconify-icon>
								</span>
								${window.T.nav.reports}
							</app-link>
						`,
					)}
				</div>
			`,
		)}
		${when(
			User.canAccess('balance'),
			() => html`
				<app-link
					href="balance/${User.company.id}"
					class=${setActiveIf('balance')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.finance })}
				>
					<span>
						<iconify-icon
							icon="mdi-bank"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.balance_invoices}
				</app-link>
			`,
		)}
	`;
};

const renderAddonNav = () => {
	if (!User.company) return '';

	return html`
		${when(
			User.canAccess('vpncode'),
			() => html`
				<app-link
					href="vpncode"
					class=${setActiveIf('vpncode')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.vpnLogins })}
				>
					<span>
						<iconify-icon
							icon="mdi-transit-connection-variant"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.daily_vpncodes}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('earnings'),
			() => html`
				<app-link
					href="earnings"
					class=${setActiveIf('earnings')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.talentRevenues })}
				>
					<span>
						<iconify-icon
							icon="mdi-hand-coin"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.stats_volume}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('news'),
			() => html`
				<app-link
					href="news/${User.company.id}"
					class=${setActiveIf('news')}
					@click=${() =>
						matomoTracker.track({ name: matomoEventNames.notifications })}
				>
					<span>
						<iconify-icon
							icon="mdi-bullhorn"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.news}
				</app-link>
			`,
		)}
		${when(
			User.canAccess('talentmonitor'),
			() => html`
				<app-link
					href="talentmonitor/${User.company.id}"
					class=${setActiveIf('talentmonitor')}
					@click=${() =>
						matomoTracker.track({
							name: matomoEventNames.monitoringPermissions,
						})}
				>
					<span>
						<iconify-icon
							icon="mdi-video-account"
							class="iconify"
						></iconify-icon>
					</span>
					${window.T.nav.talent_monitoring}
				</app-link>
			`,
		)}
		<app-link
			href="terminationprices"
			class=${setActiveIf('terminationprices')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.fees })}
		>
			<span>
				<iconify-icon
					icon="mdi-cash-minus"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.fees}
		</app-link>
	`;
};

const renderRecruitingNav = () => {
	if (!User.company) return '';

	return html`
		<app-link
			href="recruitment/worktypes"
			class=${setActiveIf('recruitment/worktypes')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.worktypes })}
		>
			<span>
				<iconify-icon
					icon="mdi-text-box-multiple"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.worktypes}
		</app-link>
		<app-link
			href="recruitment/job-offers"
			class=${setActiveIf('recruitment/job-offers')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.jobOffers })}
		>
			<span>
				<iconify-icon
					icon="mdi-file-document"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.joboffers}
		</app-link>
		<app-link
			href="recruitment/management"
			class=${setActiveIf('recruitment/management')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.management })}
		>
			<span>
				<iconify-icon
					icon="mdi-clipboard-file"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.management}
		</app-link>
		<app-link
			href="recruitment/findtalents/${User.company.id}"
			class=${setActiveIf('recruitment/findtalents')}
			@click=${() => matomoTracker.track({ name: matomoEventNames.scouting })}
		>
			<span>
				<iconify-icon
					icon="mdi-account-search"
					class="iconify"
				></iconify-icon>
			</span>
			${window.T.nav.findtalents}
		</app-link>
	`;
};

const renderNavigationSwitch = () => {
	if (!User.company) return '';

	return html`
		<div class="switch">
			<div class="copy-button">
				<span>${window.T.label.company_id} ${User.company.id}</span>
				<sl-copy-button
					value="${User.company.id}"
					hoist
					copy-label=${window.T.hint.clipboard_copy}
					success-label=${window.T.alert.success.clipboard_copy}
					@click=${() => matomoTracker.track({ name: matomoEventNames.copy })}
				></sl-copy-button>
			</div>
			<navigation-switch></navigation-switch>
		</div>
	`;
};

export const aside = (scope = 'default') => html`
	${when(
		User.device.type === 'mobile',
		() => html`
			<yd-dropdown .direction=${'top-left'}>
				<div
					slot="toggle"
					class="burgerbutton"
				>
					<div><span></span></div>
				</div>
				<nav slot="content">
					${renderDefaultNav()}
					<label for="togglemore">
						<span>
							<iconify-icon
								icon="mdi-chevron-down"
								class="iconify"
							></iconify-icon>
						</span>
						<small>${window.T.nav.more}</small>
					</label>
					<input
						type="checkbox"
						id="togglemore"
						.checked=${isExpanded([
							'earnings',
							'terminationprices',
							'talentmonitor',
							'news',
							'vpncode',
						])}
					/>
					<div>${renderAddonNav()}</div>
				</nav>
			</yd-dropdown>
		`,
		() => html`
			<nav slot="content">
				${when(scope === 'default', renderDefaultNav)}
				${when(
					scope === 'recruiting' &&
						(!globals.isProduction || User.can('flagB')),
					renderRecruitingNav,
				)}
				${when(scope === 'addons', renderAddonNav)}
				${when(
					User.isSelfservice,
					() => html`
						<a
							href="#"
							@click=${(e) => {
								e.preventDefault();
								openHubspotForm(
									{
										// eslint-disable-next-line camelcase
										cu_customer_id: User.user.id,
										company: User.user.company,
										email: User.user.email,
										lastname: User.user.name,
									},
									'7153fa89-8434-45e2-b685-0c6fb4cee49c',
								);
							}}
							class="helpButton"
						>
							<span>
								<iconify-icon
									icon="mdi-help-circle-outline"
									class="iconify"
								></iconify-icon>
							</span>
							${window.T.nav.help}
						</a>
					`,
				)}
			</nav>
			${renderNavigationSwitch()}
		`,
	)}
`;
