import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/balance/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'balance',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
