import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { Storage } from '@modules/Storage';
import { globals } from '@globals';
import { matomoTracker } from '@modules/matomoTracker';

import '@frontend/yd-components/dist/components/yd-tooltip/index.js';
import '@frontend/yd-components/dist/components/iconify-icon.js';

import type { NavigationScope } from './types';
import style from './style.scss?inline';
import { User } from '@app/User.js';

@customElement('navigation-switch')
export class NavigationSwitch extends LitElement {
	Storage = new Storage('navigationScope', 'local');

	static styles = [style];

	get _navigationScope() {
		return this.Storage.get('navigationScope') || 'default';
	}

	set _navigationScope(scope) {
		this.Storage.set('navigationScope', scope);
	}

	connectedCallback(): void {
		this.setScope(this._navigationScope);
		super.connectedCallback();
	}

	setScope(scope: NavigationScope) {
		this._navigationScope = scope;
		this.dispatchEvent(
			new CustomEvent('switchNavigationScope', {
				detail: { scope },
				bubbles: true,
				composed: true,
			}),
		);
		this.requestUpdate();
	}

	render() {
		return html`
			<div class="switch">
				<yd-tooltip tip=${window.T.nav.scope.default}>
					<iconify-icon
						icon="mdi-domain"
						@click=${() => {
							if (this._navigationScope !== 'default') {
								matomoTracker.track({ name: 'mg.nav.company.open' });
							}
							this.setScope('default');
						}}
						?data-active=${this._navigationScope === 'default'}
					></iconify-icon>
				</yd-tooltip>
				${when(
					!globals.isProduction || User.can('flagB'),
					() => html`
						<yd-tooltip tip=${window.T.nav.scope.recruitment}>
							<iconify-icon
								icon="mdi-handshake"
								@click=${() => {
									if (this._navigationScope !== 'recruiting') {
										matomoTracker.track({ name: 'mg.nav.recruiting.open' });
									}
									this.setScope('recruiting');
								}}
								?data-active=${this._navigationScope === 'recruiting'}
							></iconify-icon>
						</yd-tooltip>
					`,
				)}

				<yd-tooltip tip=${window.T.nav.scope.addon}>
					<iconify-icon
						icon="mdi:dots-horizontal"
						@click=${() => {
							if (this._navigationScope !== 'addons') {
								matomoTracker.track({ name: 'mg.nav.more.open' });
							}
							this.setScope('addons');
						}}
						?data-active=${this._navigationScope === 'addons'}
					></iconify-icon>
				</yd-tooltip>
			</div>
		`;
	}
}
