import { html, render } from 'lit';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import { showAppDialog } from '@components/app-base/helper';

const scriptSrc = 'https://js-eu1.hsforms.net/forms/v2.js';

const appendHbsptScript = () =>
	new Promise((resolve, reject) => {
		if (!document.getElementById('hbspt-js')) {
			const script = document.createElement('script');
			script.id = 'hbspt-js';
			script.setAttribute('type', 'text/javascript');
			script.setAttribute('src', scriptSrc);
			script.addEventListener('load', () => {
				resolve(true);
			});
			script.addEventListener('error', () => {
				reject(new Error(`HubSpot script (${scriptSrc}) could not be loaded`));
			});
			document.head.appendChild(script);
		} else if (window.hbspt) {
			resolve(true);
		}
	});

const initHbspt: (
	container: Element,
	formId: string,
) => Promise<HTMLFormElement> = (container, formId) =>
	new Promise((resolve, reject) => {
		appendHbsptScript()
			.then(() => {
				container.setAttribute('id', 'hbspt');
				window.hbspt.forms.create({
					region: 'eu1',
					portalId: '25869763',
					formId,
					target: '#hbspt',
					onFormReady: (form: HTMLFormElement) => {
						resolve(form);
					},
				});
			})
			.catch(reject);
	});

export const openHubspotForm = (
	prefilledFormValues: Record<string, string | number> = {},
	formId: string = '',
) => {
	showAppDialog({
		html: html`
			<div class="d-flex justify-content-center fs-1">
				<sl-spinner></sl-spinner>
			</div>
		`,
		noDismissButton: true,
		size: 'large',
		noConfirmButton: true,
		titleText: window.T.headline.support,
		didOpen: async (dialogOuterElement) => {
			const finalForm = await initHbspt(dialogOuterElement, formId);
			render(finalForm, dialogOuterElement);

			const { elements } = finalForm;
			const originInput = elements.namedItem(
				'TICKET.url_form_origin',
			) as HTMLInputElement;
			if (originInput) {
				originInput.value = window.location.href;
			}

			const appVersionInput = elements.namedItem(
				'TICKET.plattformversion',
			) as HTMLInputElement;
			if (appVersionInput) {
				appVersionInput.value = import.meta.env.VITE_APPVERSION;
			}
			Object.entries(prefilledFormValues).forEach(([idName, value]) => {
				const element = elements.namedItem(idName) as HTMLInputElement;
				if (element && !!value) element.value = value.toString();
			});
		},
	});
};
