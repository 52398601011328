import { manageRouteDefaultConfig, type ManageRoute } from '../../router';

export default <Record<string, ManageRoute>>{
	'/stats-talents/:companyuser': {
		...manageRouteDefaultConfig,
		as: 'stats-talents',
		hasRocketchat: true,
		module: () => import('./'),
	},
};
